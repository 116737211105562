@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeicons/primeicons.css";


html, body {
  font-size: 14px;
  @apply block h-full;
}

body {
  @apply bg-blue-50 dark:bg-slate-900
}

.cdk-drag-preview {
  z-index: 9000 !important;
}

.p-select-overlay {
  width: 100%;
}

@layer components {


  .xs-datatable {
    @apply relative text-left border-collapse;

    tr {
      @apply w-full;
    }

    thead.stickyh th {
      @apply sticky left-0 top-0;
      z-index: 1000;
    }

    &.vc {
      td {
        @apply even:bg-red-50 dark:even:bg-black
      }
    }

    thead {
      tr:first-child {
        th {
          @apply bg-red-200 dark:bg-blue-900
        }

        th, td {
          @apply px-4 py-5 border-0;
        }
      }

      tr:nth-child(2n) {
        th, td {
          @apply py-2 px-2;
        }
      }
    }

    th {
      @apply uppercase ;
    }

    tr {
      @apply dark:bg-slate-900 dark:even:bg-slate-900 dark:border dark:border-slate-800 dark:bg-slate-800
      bg-white even:bg-gray-50
      ;
    }

    td, th {
      @apply px-4 py-5 dark:text-slate-400;
    }
  }


  .select-option {
    @apply block py-2 px-2 -mx-4 no-underline bg-green-50 mb-1 cursor-pointer hover:bg-green-200 rounded;
  }

  .formatted-table {
    th {
      @apply text-white
    }

    td, th {
      @apply py-4 px-3;
    }
  }

  .badge {
    @apply rounded px-2 py-1 text-sm uppercase;
  }

  .action-btn {
    @apply px-4 py-2 bg-green-600 mr-2 text-white rounded;
    &.red {
      @apply bg-red-700;
    }
  }


  .center-all {
    @apply flex justify-center items-center;
  }

  // form elements
  .form-group {
    @apply mb-4;
    .form-label {
      @apply text-primary dark:text-slate-400 uppercase text-xs font-bold mb-1;
      letter-spacing: 1px
    }

    input, textarea {
      @apply border px-4 py-2 rounded-border w-full outline-0 focus:border-primary transition-all dark:bg-surface-950
      dark:focus:border-primary dark:border-surface-700 dark:text-slate-400;
    }
  }

  // buttons
  .block-btn .btn {
    @apply w-full
  }

  .btn {
    @apply px-8 py-2 bg-gray-50 rounded no-underline cursor-pointer hover:opacity-80 transition-all whitespace-nowrap;
    &-green {
      @apply bg-green-600 text-white;
    }
  }

}

.control-error {
  @apply text-red-600 uppercase font-bold text-xs;
}

/**
dashboard
 */
.dashboard-menu-item {
  @apply cursor-pointer transition-all rounded no-underline block decoration-0 hover:bg-yellow-500 px-3 py-2.5
  dark:text-gray-400 dark:hover:bg-slate-700;
  &.active {
    @apply bg-yellow-400 dark:bg-slate-600;
  }
}


.side-menu {
  @apply w-[280px] -translate-x-[300px] lg:translate-x-0 transition-all fixed h-full lg:relative overflow-auto z-[100] col-span-2 bg-white p-4 lg:p-4 shadow-2xl lg:shadow-none
  dark:bg-slate-800;
  &.menu-open {
    @apply -translate-x-0;
  }
}

.main-content {
  @apply grow relative;
}

.center-all {
  @apply flex items-center justify-center
}

h2 {
  @apply text-2xl font-bold mb-4 dark:text-slate-400;
}

.adaptive-ele {
  @apply text-black dark:text-slate-400 bg-white dark:bg-slate-800
}

.user-note {
  @apply mb-4 border-b dark:border-gray-700 border-gray-200 pb-4;
  .title {
    @apply font-bold text-lg;
  }

  .type {
    @apply bg-red-100 text-red-700 px-2 py-0 rounded uppercase hidden;
  }

  .meta {
    @apply flex gap-4 text-sm items-center
  }
}

.status-pending {
  @apply bg-red-200 dark:bg-red-800;
}

.status-done {
  @apply bg-green-200 dark:bg-green-800;
}
